/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import URL from "constant/url";
import ServerCategory from "./ServerCategory";
import DedicatedServerCategory from "./DedicatedServerCategory";
import ProxyCategory from "./ProxyCategory";
import S5ProxyCategory from "./S5ProxyCategory";
import MobileMenu from "layouts/MobileMenu";

export default function Header() {
  const navigate = useNavigate();

  return (
    <>
      <header className="header-section header-gradient">
            <div className="topbar">
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-md-6">
                            <div className="topbar-left">
                                <p className="mb-0">Get 50% Discount Offer <mark>26 Days</mark></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="topbar-right text-end">
                                <a href="login.html">Login</a>
                                <a href="signup.html">Sign up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-nav">
                <div className="container">
                    <div className="nav-menu bg-white">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-6">
                                <div className="logo-wrapper">
                                    <a href="index.html" className="logo-black">
                                      <img src="assets/img/logo.png" alt="logo" className="logo"/></a>
                                    <a href="index.html" className="logo-white">
                                      <img src="assets/img/logo-white.png" alt="logo" className="logo"/></a>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 d-none d-lg-block">
                                <div className="nav-wrapper">
                                <nav>
                                  <ul>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.Home)}
                                      >
                                        Trang chủ
                                      </a>
                                    </li>
                                    <ServerCategory />
                                    <DedicatedServerCategory />
                                    <ProxyCategory />
                                    <S5ProxyCategory />
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.Guide)}
                                      >
                                        Hướng dẫn
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.Agreement)}
                                      >
                                        Điều khoản
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.SLA)}
                                      >
                                        SLA
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.Contact)}
                                      >
                                        Liên hệ
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-6">
                                <div className="header-right d-flex align-items-center justify-content-end">
                                    <button className="dark-light-switcher me-2" id="theme-switch">
                                        <span className="light-sun"><i className="fa-solid fa-sun"></i></span>
                                        <span className="dark-moon"><i className="fa-solid fa-moon"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
      {/* <header className="gb-header header-two position-absolute w-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-5 col-lg-2 col-xl-2">
              <div
                className="logo-wrapper flex"
                style={{ display: "flex", alignItems: "center" }}
              >
                <a href={URL.Home} style={{ marginRight: "4px" }}>
                  <img
                    style={{ padding: "8px" }}
                    src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo.png`}
                    width={90}
                    height={90}
                    alt="logo"
                  />
                </a>
                <a href={URL.Home}>
                  <img
                    src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-r.png`}
                    alt="logo"
                    width={200}
                    height={35}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-xxl-8 col-lg-7 d-none d-lg-block">
              <div className="nav-wrapper">
                <nav>
                  <ul>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(URL.Home)}
                      >
                        Trang chủ
                      </a>
                    </li>
                    <ServerCategory />
                    <DedicatedServerCategory />
                    <ProxyCategory />
                    <S5ProxyCategory />
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(URL.Guide)}
                      >
                        Hướng dẫn
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(URL.Agreement)}
                      >
                        Điều khoản
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(URL.SLA)}
                      >
                        SLA
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(URL.Contact)}
                      >
                        Liên hệ
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-7 col-xxl-2 col-lg-3">
              <div className="header-right-btn d-flex align-items-center gap-5 justify-content-end">
                <a
                  href={`${URL.Login}`}
                  className="template-btn isb-small-btn isb-outline-btn rounded-4 rounded-pill btn-small d-none d-sm-block"
                >
                  Đăng nhập
                </a>
                <a
                  href={`${URL.Register}`}
                  className="template-btn isb-small-btn isb-gd-bg rounded-4 rounded-pill btn-small d-none d-sm-block"
                >
                  Đăng ký
                </a>
                <i
                  className="d-lg-none fa-solid fa-bars-staggered"
                  style={{ color: "white", fontSize: '20px' }}
                  onClick={() => {
                    const el = document.getElementById("mobile-menu");
                    if (el) el.style.display = "block";
                  }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </header> */}
      <MobileMenu />
    </>
  );
}
