/* eslint-disable jsx-a11y/iframe-has-title */

import URL from "constant/url";
import { useNavigate } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="hm2-footer pt-120">
      <div className="container">
        <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="footer-widget footer-nav-widget">
                    <h5 className="widget-title position-relative text-white mb-5">Hostim Hosting</h5>
                    <ul className="footer-nav">
                        <li><a href="pricing.html">Pricing Plans</a></li>
                        <li><a href="features.html">Hostim Features</a></li>
                        <li><a href="#">Add-Ons</a></li>
                        <li><a href="#">Cloudflare Integration</a></li>
                        <li><a href="#">APM Tool</a></li>
                        <li><a href="#">Dev Hostim</a></li>
                        <li><a href="contact.htm">Hostim Support</a></li>
                        <li><a href="#">Free Migration</a></li>
                    </ul>

                </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="footer-widget footer-nav-widget">
                    <h5 className="widget-title position-relative text-white mb-5">Company Links</h5>
                    <ul className="footer-nav">
                        <li><a href="about.html">About Us</a></li>
                        <li><a href="#">Careers</a></li>
                        <li><a href="#">Clients & Case Studies</a></li>
                        <li><a href="contact.html">Contact Us</a></li>
                        <li><a href="testimonials.html">Kinsta Reviews</a></li>
                        <li><a href="#">Partners</a></li>
                        <li><a href="#">Why Us</a></li>
                        <li><a href="#">Affiliate Program</a></li>
                    </ul>

                </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="footer-widget footer-nav-widget">
                    <h5 className="widget-title position-relative text-white mb-5">Resources Links</h5>
                    <ul className="footer-nav">
                        <li><a href="#">All Resources</a></li>
                        <li><a href="blog-grids.html">Blog</a></li>
                        <li><a href="#">Knowledge Base</a></li>
                        <li><a href="contact.html">Help Center</a></li>
                        <li><a href="#">Feature Updates</a></li>
                        <li><a href="#">Agency Directory</a></li>
                        <li><a href="#">Affiliate Academy</a></li>
                        <li><a href="#">System Status</a></li>
                    </ul>

                </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="footer-widget footer-nav-widget">
                    <h5 className="widget-title position-relative text-white mb-5">Products & Solution</h5>
                    <ul className="footer-nav">
                        <li><a href="wp-hosting.html">Managed WordPress Hosting</a></li>
                        <li><a href="shared-hosting.html">Shared Hosting</a></li>
                        <li><a href="vps-hosting.html">VPS Hosting</a></li>
                        <li><a href="index-3.html">Game Hosting</a></li>
                        <li><a href="#">WooCommerce Hosting</a></li>
                        <li><a href="#">Multisite Hosting</a></li>
                        <li><a href="#">Secure Hosting</a></li>
                        <li><a href="#">Hosting for Publishers</a></li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      <div className="hm2-footer-copyright">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-lg-4 col-md-6 order-2 order-lg-1">
                      <div className="copyright-txt mt-3 mt-lg-0">
                          <p className="mb-0 text-white">&copy; 2022 Hostim. All rights reserved</p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 order-1 order-lg-2">
                      <div className="footer-logo text-start text-lg-center">
                          <a href="index.html"><img src="assets/img/logo-white.png" alt="logo" className="logo"/></a>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 order-3">
                      <div className="footer-social text-start text-lg-end mt-3 mt-lg-0">
                          <a href="#"><i className="fab fa-twitter"></i></a>
                          <a href="#"><i className="fab fa-facebook-f"></i></a>
                          <a href="#"><i className="fab fa-dribbble"></i></a>
                          <a href="#"><i className="fab fa-behance"></i></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </footer>
  );
}
