/* eslint-disable no-script-url */
import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function MobileMenu() {
  return (
    <>
      {/*mobile menu*/}
      <div className="mobile-menu bg-white deep-shadow" id="mobile-menu">
        <i
          className="fa-solid fa-xmark"
          style={{
            right: "20px",
            top: "15px",
            position: "absolute",
            fontSize: "30px",
          }}
          onClick={() => {
            const el = document.getElementById("mobile-menu");
            if (el) el.style.display = "none";
          }}
        ></i>
        <a href={URL.Home} className="logo-wrapper">
          <img
            src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-r.png`}
            alt="logo"
            width={200}
            height={35}
          />
        </a>
        <nav className="mobile-menu-wrapper mt-40">
          <ul>
            <li className="has-submenu">
              <a
                href="#"
                onClick={(e) => {
                  if (
                    !e.currentTarget.nextElementSibling?.classList.contains(
                      "show-list"
                    )
                  ) {
                    e.currentTarget.nextElementSibling?.classList.add(
                      "show-list"
                    );
                  } else {
                    e.currentTarget.nextElementSibling?.classList.remove(
                      "show-list"
                    );
                  }
                }}
              >
                VPS
              </a>
              <ul>
                <li>
                  <a href={URL.ListServerVN}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/vietnam.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Việt Nam (VN)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerSG}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/singapore.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Singapore (SG)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerAU}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/australia.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Úc (AU)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerEU}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/european-union.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Châu Âu (PTU)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListVPSGPU}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/gpu.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS GPU</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerUK}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/united-kingdom.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Anh (UK)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerDE}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/germany.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Đức (DE)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerFR}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/france.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Pháp (FR)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerNL}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/netherlands.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Hà Lan (NL)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerUS}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/united-states.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Mỹ (US)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListServerCA}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/canada.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>VPS Canada (CA)</h6>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
            <li className="has-submenu">
              <a
                href="#"
                onClick={(e) => {
                  if (
                    !e.currentTarget.nextElementSibling?.classList.contains(
                      "show-list"
                    )
                  ) {
                    e.currentTarget.nextElementSibling?.classList.add(
                      "show-list"
                    );
                  } else {
                    e.currentTarget.nextElementSibling?.classList.remove(
                      "show-list"
                    );
                  }
                }}
              >
                Dedicated Server
              </a>
              <ul>
                <li>
                  <a href={URL.ListDedicatedServerEU}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/european-union.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Dedicated Châu Âu</h6>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href={URL.ListDedicatedServerUS}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/united-states.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Dedicated Mỹ</h6>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
            <li className="has-submenu">
              <a
                href="#"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (
                    !e.currentTarget.nextElementSibling?.classList.contains(
                      "show-list"
                    )
                  ) {
                    e.currentTarget.nextElementSibling?.classList.add(
                      "show-list"
                    );
                  } else {
                    e.currentTarget.nextElementSibling?.classList.remove(
                      "show-list"
                    );
                  }
                }}
              >
                Proxy
              </a>
              <ul>
                <li>
                  <a href={URL.ListProxyVN}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/vietnam.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Việt Nam (VN)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListProxySG}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/singapore.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Singapore (SG)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListProxyAU}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/australia.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Úc (AU)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListProxyUK}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/united-kingdom.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Anh (UK)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListProxyDE}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/germany.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Đức (DE)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListProxyFR}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/france.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Pháp (FR)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListProxyNL}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/netherlands.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Hà Lan (NL)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListProxyUS}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/united-states.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Mỹ (US)</h6>
                      </div>
                    </div>
                  </a>
                  <a href={URL.ListProxyCA}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/flags/canada.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>Proxy Canada (CA)</h6>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
            <li className="has-submenu">
              <a
                href="#"
                onClick={(e) => {
                  if (
                    !e.currentTarget.nextElementSibling?.classList.contains(
                      "show-list"
                    )
                  ) {
                    e.currentTarget.nextElementSibling?.classList.add(
                      "show-list"
                    );
                  } else {
                    e.currentTarget.nextElementSibling?.classList.remove(
                      "show-list"
                    );
                  }
                }}
              >
                S5 Proxy
              </a>
              <ul>
                <li>
                  <a href={URL.List922S5Proxy}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/partner/922.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>922 Proxy</h6>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href={URL.ListPIAS5Proxy}>
                    <div className="menu-list-wrapper d-flex align-items-center">
                      <img
                        src="assets/partner/pia.png"
                        alt="server"
                        className="list-logo"
                      />
                      <div className="menu-list-content-right ms-3">
                        <h6>PIA Proxy</h6>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href={URL.Guide}>Hướng dẫn</a>
            </li>
            <li>
              <a href={URL.Agreement}>Điều khoản</a>
            </li>
            <li>
              <a href={URL.SLA}>SLA</a>
            </li>
            <li>
              <a href={URL.Contact}>Liên hệ</a>
            </li>
            <li>
              <a href={URL.Login}>Đăng nhập</a>
            </li>
            <li>
              <a href={URL.Register}>Đăng ký</a>
            </li>
          </ul>
        </nav>
        <div className="contact-info mt-60">
          <h4 className="mb-20">Thông tin</h4>
          <p>Số 39 ngõ 144 phố Quan Nhân, Nhân Chính, Thanh Xuân, Hà Nội</p>
          <p>(+84) 962085904</p>
          <p>admin@vpsmanh24h.com</p>
          <div className="contact-social">
            <a
              href="https://www.facebook.com/vpsmanh24hvn"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a href="https://t.me/VT_VPS" target="_blank" rel="noreferrer">
              <i className="fab fa-telegram" />
            </a>
          </div>
        </div>
      </div>
      {/*mobile menu*/}
    </>
  );
}
